import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';

import { Fade } from 'components/common/Animations';
import { PageSection } from 'components/common/PageSection/PageSection';
import { PageContent } from 'components/common/PageContent/PageContent';
import {
  SecondaryHeader,
  SectionDescription,
} from 'components/common/Typography';
import {
  ImageBoxes,
  ImageBoxesPropTypes,
} from 'components/common/ImageBoxes/ImageBoxes';

export const Team = ({ description, employees, isMultiRow, title }) => (
  <PageSection backgroundColor="white">
    <PageContent>
      <Fade>
        <SecondaryHeader isBolded isCentered isUnderlined>
          {title}
        </SecondaryHeader>
        {description && (
          <SectionDescription isCentered maxWidth="800px">
            {description}
          </SectionDescription>
        )}
      </Fade>
      <Fade>
        <ImageBoxes isMultiRow={isMultiRow} items={employees} />
      </Fade>
    </PageContent>
  </PageSection>
);

export const TeamPropTypes = {
  description: PropTypes.string.isRequired,
  employees: ImageBoxesPropTypes.isRequired,
  isMultiRow: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

Team.propTypes = TeamPropTypes;

export const teamSectionFragment = graphql`
  fragment TeamSectionFragment on MarkdownRemark {
    frontmatter {
      team {
        description
        title
        isMultiRow
        employees {
          description
          image {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
          name
        }
      }
    }
  }
`;
