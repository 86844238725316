import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';

import { Fade } from 'components/common/Animations';
import { PageSection } from 'components/common/PageSection/PageSection';
import {
  SecondaryHeader,
  SectionDescription,
} from 'components/common/Typography';

import { Button, GridWrapper } from './PricesStyles';

export const Prices = ({ button, description, title }) => (
  <PageSection backgroundColor="light">
    <Fade>
      <GridWrapper>
        <SecondaryHeader isBolded isCentered isUnderlined>
          {title}
        </SecondaryHeader>
        <SectionDescription isCentered maxWidth="800px">
          {description}
        </SectionDescription>
        <Button>{button}</Button>
      </GridWrapper>
    </Fade>
  </PageSection>
);

export const PricesPropTypes = {
  button: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

Prices.propTypes = PricesPropTypes;

export const pricesSectionFragment = graphql`
  fragment PricesSectionFragment on MarkdownRemark {
    frontmatter {
      prices {
        button
        description
        title
      }
    }
  }
`;
