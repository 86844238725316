import styled from 'styled-components';

import Img from 'gatsby-image';

import { Link as GatsbyLink } from 'gatsby';

export const Image = styled(Img)`
  min-height: 250px;
  max-height: 300px;
`;

export const Wrapper = styled.div`
  box-shadow: ${({ theme }) => theme.color.shadow};
`;

export const ContentWrapper = styled.div`
  padding: 20px 20px 25px;
  background-color: ${({ theme }) => theme.color.white};
`;

export const Link = styled(GatsbyLink)`
  display: inline-block;
  margin-top: 20px;
  font-size: 18px;
  color: ${({ theme }) => theme.color.primary};
  text-decoration: none;
  font-weight: 500;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.color.primary};
    top: calc(100% + 4px);
    left: 50%;
    transform: translateX(-50%);
    transition: width 0.2s ease;
  }

  &:hover:after {
    width: 90%;
  }
`;
