import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';

import { Fade } from 'components/common/Animations';
import { FluidImagePropTypes } from 'types';
import { withMedia } from 'utils';

import {
  SecondaryHeader,
  SectionDescription,
} from 'components/common/Typography';

import { AboutSection, AboutWrapper, Image, AboutButton } from './AboutStyles';

export const About = withMedia(
  ({ button, description, image, isMatched, title }) => (
    <AboutSection>
      <Fade>
        <AboutWrapper>
          <SecondaryHeader
            isBolded
            isCentered={!isMatched}
            isLight
            isUnderlined
          >
            {title}
          </SecondaryHeader>
          <SectionDescription color="light" isLight>
            {description}
          </SectionDescription>
          <AboutButton>{button}</AboutButton>
        </AboutWrapper>
      </Fade>
      {isMatched && (
        <Fade>
          <Image
            alt={title}
            as={image?.childImageSharp?.fluid ? Image : 'img'}
            fluid={image?.childImageSharp?.fluid}
            src={image}
          />
        </Fade>
      )}
    </AboutSection>
  )
);

export const AboutPropTypes = {
  button: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([FluidImagePropTypes, PropTypes.string])
    .isRequired,
  title: PropTypes.string.isRequired,
};

About.propTypes = AboutPropTypes;

export const aboutSectionFragment = graphql`
  fragment AboutSectionFragment on MarkdownRemark {
    frontmatter {
      about {
        button
        description
        image {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        title
      }
    }
  }
`;
