import styled, { css } from 'styled-components';
import { media } from 'utils';

export const IconBoxWrapper = styled.div`
  display: grid;
  justify-items: center;
  text-align: center;
`;

export const Icon = styled.img`
  max-width: 50px;
  margin-bottom: 10px;

  ${media.large(css`
    max-width: 100px;
    margin-bottom: 20px;
  `)};
`;
