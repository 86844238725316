import React from 'react';
import PropTypes from 'prop-types';

import { ThirdHeader, Description } from 'components/common/Typography';

import { Icon, IconBoxWrapper } from './IconBoxStyles';

export const IconBox = ({ description, image, name }) => (
  <IconBoxWrapper>
    <Icon alt={name} src={image} />
    <ThirdHeader isBolded>{name}</ThirdHeader>
    <Description>{description}</Description>
  </IconBoxWrapper>
);

IconBox.propTypes = {
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
