import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';

import { Slider, SliderPropTypes } from 'components/common/Slider/Slider';

import { Hero, HeroPropTypes } from './Hero/Hero';
import { Courses, CoursesPropTypes } from './Courses/Courses';
import { About, AboutPropTypes } from './About/About';
import { Team, TeamPropTypes } from './Team/Team';
import { Prices, PricesPropTypes } from './Prices/Prices';
import { News, NewsPropTypes } from './News/News';

// TODO add types
// eslint-disable-next-line
export const Home: React.FunctionComponent<any> = ({
  about,
  courses,
  hero,
  images,
  news,
  prices,
  team,
}) => (
  <>
    <Hero {...hero} />
    <Courses {...courses} />
    <About {...about} />
    <Team {...team} />
    <Slider images={images} />
    <News news={news} />
    <Prices {...prices} />
  </>
);

export const HomePropTypes = {
  about: PropTypes.shape(AboutPropTypes).isRequired,
  courses: PropTypes.shape(CoursesPropTypes).isRequired,
  hero: PropTypes.shape(HeroPropTypes).isRequired,
  prices: PropTypes.shape(PricesPropTypes).isRequired,
  team: PropTypes.shape(TeamPropTypes).isRequired,
  ...SliderPropTypes,
};

Home.propTypes = {
  ...HomePropTypes,
  ...NewsPropTypes,
};

export const homePageFragment = graphql`
  fragment HomePageFragment on MarkdownRemark {
    ...AboutSectionFragment
    ...CoursesSectionFragment
    ...HeroSectionFragment
    ...PricesSectionFragment
    ...TeamSectionFragment
    ...SliderFragment
  }
`;
