import styled, { css } from 'styled-components';
import { media } from 'utils';

import { Button as BaseButton } from 'components/common/Button/Button';

export const GridWrapper = styled.div`
  display: grid;
  justify-items: center;
`;

export const Button = styled(BaseButton)`
  margin-top: 20px;

  ${media.large(css`
    margin-top: 30px;
  `)};
`;
