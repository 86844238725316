import styled, { css } from 'styled-components';
import { media } from 'utils';

import Img from 'gatsby-image';
import { Button as Base } from 'components/common/Button/Button';

export const HeroWrapper = styled.section`
  min-height: 300px;
  display: grid;
  grid-template-columns: 1fr;
  padding: ${({ theme }) => `${theme.layout.gapMobileX}`};

  ${media.large(css`
    padding: ${({ theme }) => `${theme.layout.gapDesktopX}`};
  `)};
`;

export const Image = styled(Img)`
  grid-area: 1 / 1 / -1 / -1;
  margin: ${({ theme }) => `-${theme.layout.gapMobileX}`};
  z-index: 1;

  ${media.large(css`
    margin: ${({ theme }) => `-${theme.layout.gapDesktopX}`};
  `)};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-area: 1 / 1 / -1 / -1;
  z-index: 10;
`;

export const Overlay = styled.div`
  width: calc(100% + ${({ theme }) => `${theme.layout.gapMobileX}`} * 2);
  height: calc(100% + ${({ theme }) => `${theme.layout.gapMobileX}`} * 2);
  margin-top: ${({ theme }) => `-${theme.layout.gapMobileX}`};
  margin-left: ${({ theme }) => `-${theme.layout.gapMobileX}`};
  grid-area: 1 / 1 / -1 / -1;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);

  ${media.large(css`
    width: calc(100% + ${({ theme }) => `${theme.layout.gapDesktopX}`} * 2);
    height: calc(100% + ${({ theme }) => `${theme.layout.gapDesktopX}`} * 2);
    margin-top: ${({ theme }) => `-${theme.layout.gapDesktopX}`};
    margin-left: ${({ theme }) => `-${theme.layout.gapDesktopX}`};
  `)};
`;

export const Button = styled(Base)`
  margin-top: 15px;

  ${media.large(css`
    margin-top: 20px;
  `)};
`;
