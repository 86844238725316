import styled, { css } from 'styled-components';
import { media } from 'utils';

import { Button } from 'components/common/Button/Button';
import Img from 'gatsby-image';

export const AboutSection = styled.section`
  display: grid;
  grid-template: auto / 1fr;
  background-color: ${(props) => props.theme.color.darkBlue};

  ${media.large(css`
    grid-template: auto / 1fr 1fr;
  `)};
`;

export const AboutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: ${({ theme }) =>
    `${theme.layout.gapMobileY} ${theme.layout.gapMobileX}`};

  ${media.large(css`
    padding: ${({ theme }) => `${theme.layout.gapDesktopY}`};
    align-items: flex-start;
    text-align: left;
  `)};
`;

export const Image = styled(Img)`
  max-height: 700px;
  display: none;

  ${media.large(css`
    display: block;
  `)};
`;

export const AboutButton = styled(Button)`
  margin-top: 25px;

  ${media.large(css`
    margin-top: 30px;
  `)};
`;
