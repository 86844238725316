import React from 'react';

import { graphql } from 'gatsby';

import { Fade } from 'components/common/Animations';
import { NewsListItemsPropTypes } from 'components/NewsList/NewsList';
import { PageSection } from 'components/common/PageSection/PageSection';
import { PageContent } from 'components/common/PageContent/PageContent';
import { SecondaryHeader } from 'components/common/Typography';
import { GridBoxes } from 'components/common/GridBoxes/GridBoxes';
import { CardBox } from 'components/common/CardBox/CardBox';

export const News = ({ news }) =>
  news.length ? (
    <PageSection backgroundColor="white">
      <PageContent>
        <Fade>
          <SecondaryHeader isBolded isCentered isUnderlined>
            News
          </SecondaryHeader>
        </Fade>
        <Fade>
          <GridBoxes itemsCount={news.length}>
            {news.map(({ node }) => (
              <CardBox key={node.id} {...node} />
            ))}
          </GridBoxes>
        </Fade>
      </PageContent>
    </PageSection>
  ) : null;

export const NewsPropTypes = {
  news: NewsListItemsPropTypes,
};

News.propTypes = NewsPropTypes;

News.defaultProps = {
  news: [],
};

export const newsSectionFragment = graphql`
  fragment NewsSectionFragment on MarkdownRemarkConnection {
    edges {
      node {
        excerpt(pruneLength: 200)
        id
        fields {
          slug
        }
        frontmatter {
          author
          avatarImage {
            publicURL
          }
          title
          date(formatString: "DD MMMM, YYYY")
          heroImage {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`;
