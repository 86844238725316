import React from 'react';

import { NewsListItemPropTypes } from 'components/NewsList/NewsListItem';

import { ThirdHeader, Description } from 'components/common/Typography';
import { UserAvatar } from 'components/common/UserAvatar/UserAvatar';
import { Image, Link, ContentWrapper, Wrapper } from './CardBoxStyles';

export const CardBox = ({
  excerpt,
  fields,
  frontmatter: { heroImage, title, ...userProps },
}) => (
  <Wrapper>
    <Image
      alt={title}
      as={heroImage?.childImageSharp?.fluid ? Image : 'img'}
      fluid={heroImage?.childImageSharp?.fluid}
      src={heroImage}
    />
    <ContentWrapper>
      <UserAvatar {...userProps} />
      <ThirdHeader isBolded>{title}</ThirdHeader>
      <Description>{excerpt}</Description>
      <Link to={fields.slug}>Zobacz więcej</Link>
    </ContentWrapper>
  </Wrapper>
);

CardBox.propTypes = NewsListItemPropTypes;
