import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';

import { FluidImagePropTypes } from 'types';

import { MainHeader, SectionDescription } from 'components/common/Typography';

import { HeroWrapper, Image, Content, Button, Overlay } from './HeroStyles';

export const Hero = ({ button, description, image, title }) => (
  <HeroWrapper>
    <Overlay />
    <Image
      alt={title}
      as={image?.childImageSharp?.fluid ? Image : 'img'}
      fluid={image?.childImageSharp?.fluid}
      src={image}
    />

    <Content>
      <MainHeader isBolded isLight>
        {title}
      </MainHeader>
      {description && (
        <SectionDescription color="white" isCentered maxWidth="700px">
          {description}
        </SectionDescription>
      )}
      <Button>{button}</Button>
    </Content>
  </HeroWrapper>
);

export const HeroPropTypes = {
  button: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.oneOfType([FluidImagePropTypes, PropTypes.string])
    .isRequired,
  title: PropTypes.string.isRequired,
};

Hero.propTypes = HeroPropTypes;

Hero.defaultProps = {
  description: '',
};

export const heroSectionFragment = graphql`
  fragment HeroSectionFragment on MarkdownRemark {
    frontmatter {
      hero {
        button
        description
        title
        image {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`;
