import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';

import { ImagePropTypes } from 'types';

import { Fade } from 'components/common/Animations';
import { PageSection } from 'components/common/PageSection/PageSection';
import { PageContent } from 'components/common/PageContent/PageContent';
import { IconBox } from 'components/common/IconBox/IconBox';
import { GridBoxes } from 'components/common/GridBoxes/GridBoxes';
import {
  SecondaryHeader,
  SectionDescription,
} from 'components/common/Typography';

export const Courses = ({ description, title, types }) => (
  <PageSection backgroundColor="light">
    <PageContent>
      <Fade>
        <SecondaryHeader isBolded isCentered isUnderlined>
          {title}
        </SecondaryHeader>
        <SectionDescription isCentered maxWidth="800px">
          {description}
        </SectionDescription>
      </Fade>
      <Fade>
        <GridBoxes itemsCount={types.length}>
          {types.map(({ description: typeDescription, image, name }) => (
            <IconBox
              key={name}
              description={typeDescription}
              image={image?.publicURL || image}
              name={name}
            />
          ))}
        </GridBoxes>
      </Fade>
    </PageContent>
  </PageSection>
);

export const CoursesPropTypes = {
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      image: PropTypes.oneOfType([ImagePropTypes, PropTypes.string]),
      name: PropTypes.string,
    })
  ).isRequired,
};

Courses.propTypes = CoursesPropTypes;

export const coursesSectionFragment = graphql`
  fragment CoursesSectionFragment on MarkdownRemark {
    frontmatter {
      courses {
        description
        title
        types {
          description
          image {
            publicURL
          }
          name
        }
      }
    }
  }
`;
