import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';

import { PageMetaDataPropTypes } from 'types';

import { Layout } from 'components/Layout/Layout';
import { Home, HomePropTypes } from 'components/Home/Home';
import { NewsListItemsPropTypes } from 'components/NewsList/NewsList';

const HomePage = ({
  data: {
    allMarkdownRemark: { edges },
    markdownRemark: {
      // eslint-disable-next-line react/prop-types
      frontmatter: { pageDescription, pageKeywords, pageTitle, ...rest },
    },
  },
}) => (
  <Layout
    description={pageDescription}
    keywords={pageKeywords}
    title={pageTitle}
  >
    <Home news={edges} {...rest} />
  </Layout>
);

HomePage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: NewsListItemsPropTypes,
    }),
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        ...PageMetaDataPropTypes,
        ...HomePropTypes,
      }),
    }),
  }).isRequired,
};

export default HomePage;

export const homePageQuery = graphql`
  query HomePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      ...PageMetaDataFragment
      ...HomePageFragment
    }
    allMarkdownRemark(
      limit: 3
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "news-page" } } }
    ) {
      ...NewsSectionFragment
    }
  }
`;
